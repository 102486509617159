import { Dispatch, FC, SetStateAction, SyntheticEvent, useContext, useEffect, useState } from "react";
import "../../styles/modalWindow.css";
import { IProjectCreateReq, IProjectEditReq } from "../../../models/ProjectModels";
import { useParams } from "react-router-dom";
import { Context } from "../../..";
import useOutsideAlerter from "../../../customHooks/useOutsideAlert";
import "../../Task/TaskAddEditForm/TaskAddEditForm.css";
import "./AddEditProject.css";
import { AppContext } from "../../../App";
import { FloatLabel } from "primereact/floatlabel";
import { Calendar } from "primereact/calendar";
import Checkbox from "../Checkbox/Checkbox";
import EditIconWhite from "../../../assets/EditIconWhite.svg"
import { FileResponse } from "../../../models/response/FileResponce";
import { getFileUrlFromId } from "../../../helpers/getFileUrlFromId";

//TODO нужно продумать из какого запроса будет приходить информация и куда поместить этот интерфейс
interface IProjectInfo {
    name: string;
    description?: string;
    startDate: Date;
    endDate: Date | null;
    photoId: number | null;
    abbreviation: string;
    //TODO добавить команду проекта
}

interface IEditProject {
    setOpen: (open: boolean) => void;
    projectInfo?: IProjectInfo;
    onAction?: () => void;
    editSubproject?: boolean;
}

const AddEditProject: FC<IEditProject> = ({ setOpen, projectInfo, onAction, editSubproject }) => {
    const { store } = useContext(Context);
    const { id } = useParams();
    const { getData, showToast } = useContext(AppContext);
    const [name, setName] = useState<string | undefined>(projectInfo?.name);
    const [abbreviation, setAbbreviation] = useState<string | undefined>(projectInfo?.abbreviation);
    const [abbreviationError, setAbbreviationError] = useState<string>();
    const [nameError, setNameError] = useState<string>();
    const [description, setDescription] = useState<
        string | undefined
    >(projectInfo?.description);
    const [startDate, setStartDate] = useState<Date | undefined>(projectInfo?.startDate ? new Date(projectInfo.startDate) : undefined);
    const [endDate, setEndDate] = useState<Date | null>(projectInfo?.endDate ? new Date(projectInfo.endDate) : null);
    const [endDateEmpty, setEndDateEmpty] = useState<boolean>(projectInfo ? projectInfo.endDate === null : false);
    const [datesError, setDatesError] = useState<string>();
    const tabs: string[] = ["Основное", "Роли", "Сроки проекта"];
    const [tab, setTab] = useState<number>(0);
    const [imageId, setImageId] = useState<number>(projectInfo?.photoId ?? 1);
    const EDIT_MODE = projectInfo !== undefined;
    const MAX_DESCRIPTION_LENGTH = 250;


    function cleanInput() {
        const inputElement = document.getElementById('change__logo--id');
        if (inputElement instanceof HTMLInputElement) {
            inputElement.value = '';
        } else {
            console.error('Элемент с id="change__logo--id" не найден!');
        }
    }

    const onChangeLogo = (e: any, mode: string) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }

        (async () => {
            const fileInfo: FileResponse = await store.addFile(files[0]);
            setImageId(fileInfo.id ? fileInfo.id : 1);
        })();
        cleanInput();
    };

    const onCreate = (e: SyntheticEvent) => {
        const nameErr = validateName();
        const datesErr = validateDates();
        const nameAbbr = validateAbbriviation();

        if (nameErr || datesErr || nameAbbr)
            return;

        const body: IProjectCreateReq = {
            name: name!,
            description: description,
            startDate: startDate!,
            endDate: endDateEmpty ? null : endDate!,
            photoId: imageId,
            abbreviation: abbreviation?.toUpperCase()!
        };

        (async () => {
            await store.createProject(body);
            await store.getProjectGeneralInfo(Number(id));
            await store.getAllProject();
            await getData();
            showToast("Проект создан");
            if (onAction) await onAction();
        })();
        setOpen(false);
    }

    const onEdit = () => {
        const nameErr = validateName();
        const datesErr = validateDates();
        const nameAbbr = validateAbbriviation();

        if (nameErr || datesErr || nameAbbr)
            return;

        const body: IProjectEditReq = {
            projectId: Number(id),
            name: name,
            description: description,
            startDate: startDate,
            endDate: endDateEmpty ? null : endDate!,
            photoId: imageId,
            abbreviation:abbreviation!,
        };
        (async () => {
            await store.editProject(body);
            await store.getProjectGeneralInfo(Number(id));
            await store.getAllProject();
            await getData();
            showToast("Изменения сохранены");
            if (onAction) await onAction();
        })();
        setOpen(false);
    }
    const validateName = (): boolean => {
        
        let nameErr = undefined;

        if (!name || !name.length) {
            nameErr = 'Название проекта не заполнено';
        }

        setNameError(nameErr);
        return nameErr !== undefined;
    }

    const validateDates = (): boolean => {
        let datesErr = undefined;
        
        if (!startDate && !endDateEmpty && !endDate)
            datesErr = "Заполните сроки проекта";
        else if (startDate && endDate && endDate < startDate)
            datesErr = "Дата начала не может быть больше даты окончания";
        else if (!startDate)
            datesErr = "Заполните дату начала проекта";
        else if (!endDateEmpty && !endDate)
            datesErr = "Заполните дату окончания проекта";

        setDatesError(datesErr);

        return datesErr !== undefined;
    }

    function changePage(id: number) {
        setTab(id);
    }

    const changeName = (value: string): void => {
        setName(value);
        setAbbreviation(value.slice(0, 3).toUpperCase());
    }

    const changeAbbriviation = (value: string): void => {
        setAbbreviation(value.slice(0, 3));
    }

    const validateAbbriviation = () => {
        let nameErr = undefined;
        const regex = /^[a-zA-ZА-Яа-я0-9]*$/;
        if(abbreviation?.length && !regex.test(abbreviation)){
            nameErr = 'Пожалуйста, убедитесь, что в строке используются только буквы (A-Z, a-z, А-Я, а-я) и цифры (0-9)';
        }
        if (!abbreviation && !abbreviation?.length) {
            nameErr = 'Аббревиатура не заполнена';
        }

        if (abbreviation && (abbreviation.length < 3 || abbreviation.length > 5)) {
            nameErr = 'Длина аббревиатуры не должна быть менее 3-х и не более 5-ти символов';
        }
        setAbbreviationError(nameErr);
        return nameErr !== undefined;
    }

    return (
        <div className="modal-window-overlay">

            <div className={`modal-window-container modal-window-container-full-screen ${editSubproject ? "subproject" : ""}`} >
                <div>
                    <div className="task__create--edit--form__tab--menu">
                        {tabs.map((tabName, i) => (
                            <button
                                className={`task__create--edit--form__tab--menu--button
                                ${(tab == i
                                        ? "task__create--edit--form__tab--menu--button__selected"
                                        : "")
                                    + " " +
                                    ((nameError) && tabName == "Основное"
                                        ? " task__create--edit__form--tab__panel__err"
                                        : "") +
                                    ((datesError) && tabName == "Сроки проекта"
                                        ? " task__create--edit__form--tab__panel__err"
                                        : "")
                                    }`
                                }
                                onClick={() => (changePage(i))}>
                                {tabName}
                            </button>
                        ))}
                    </div>
                    {tab === 0 ?
                        <div className="margin_top_editProj">
                            {editSubproject !== true ?
                                (<div>
                                    <label
                                        className="input_label label_24 "
                                        htmlFor="name_input"
                                    >
                                        Обложка
                                    </label>
                                    <div className="cover_project_wrapper">
                                        <div className="cover_project_main_item">
                                            <img src={getFileUrlFromId(imageId)} alt="" />
                                        </div>
                                        <div className="basic__tab--setting__elem--company__logo__button">
                                            <label className="basic__tab--setting__elem--input-file basic__tab--setting__elem--input-file__task--size">
                                                <input onChange={(e) => { onChangeLogo(e, 'logo') }} type="file" id="change__logo--id" />
                                                <span className="basic__tab--setting__elem--input-file__task-btn">
                                                    <img src={EditIconWhite} />
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="cover_project_default_images">
                                        <div className="cover_project_default_image"
                                            onClick={() => setImageId(1)}>
                                            <img alt="projectCover1" src={getFileUrlFromId(1)}></img>
                                        </div>
                                        <div className="cover_project_default_image"
                                            onClick={() => setImageId(2)}>
                                            <img alt="projectCover2" src={getFileUrlFromId(2)}></img>
                                        </div>
                                        <div className="cover_project_default_image"
                                            onClick={() => setImageId(3)}>
                                            <img alt="projectCover3" src={getFileUrlFromId(3)}></img>
                                        </div>
                                    </div>
                                </div>) : null}
                            <label
                                className="input_label label_24"
                                htmlFor="name_input"
                            >
                                Название проекта*
                            </label>
                            <input
                                id="name_input"
                                className={`input_form label_24 ${nameError ? "textarea_with_error" : ""}`}
                                onChange={(e: any) => changeName(e.target.value)}
                                onBlur={() => validateName()}
                                type="text"
                                placeholder="Введите название проекта"
                                value={name}
                            />
                            {nameError ? (
                                <p className="error_message">{nameError}</p>
                            ) : null}
                            <label
                                className="input_label label_24"
                                htmlFor="name_input"
                            >
                                Аббревиатура проекта*
                            </label>
                            <input
                                id="abbreviation_input"
                                className={`input_form label_24 ${abbreviationError ? "textarea_with_error" : ""}`}
                                onChange={(e: any) => setAbbreviation(e.target.value.toUpperCase())}
                                onBlur={() => validateAbbriviation()}
                                type="text"
                                placeholder="Введите aббревиатуру проекта"
                                value={abbreviation}
                            />
                            {abbreviationError ? (
                                <p className="error_message">{abbreviationError}</p>
                            ) : null}
                            <label
                                className="input_label label_24"
                                htmlFor="desk_input"
                            >
                                Описание проекта
                            </label>
                            <textarea
                                className="input_form textarea_input label_24"
                                value={description}
                                placeholder="Опишите свой проект"
                                id="desk_input"
                                onChange={(e: any) =>
                                    setDescription(e.target.value.slice(0, MAX_DESCRIPTION_LENGTH))
                                }
                            ></textarea>
                            <div className="task__create--edit__form--tab__panel--textarea__lenght" style={{ bottom: "40px" }}>
                                <div>
                                    {description?.length! > MAX_DESCRIPTION_LENGTH ? description?.slice(0, MAX_DESCRIPTION_LENGTH)?.length : description?.length}/{MAX_DESCRIPTION_LENGTH}
                                </div>
                            </div>
                        </div>
                        : tab === 1 ? <div>Здесь скоро будут роли</div> :
                            <div>
                                <div
                                    className={`calendar--block__flex ${datesError ? "input_with_error" : ""
                                        }`}
                                >
                                    <div className="calendar--block__flex--inp">
                                        <FloatLabel>
                                            <Calendar
                                                dateFormat="dd.mm.yy"
                                                onChange={(event) =>
                                                    setStartDate(
                                                        (event as any).target.value
                                                    )
                                                }
                                                value={startDate ? new Date(startDate) : undefined}
                                            />
                                            <label htmlFor="Дата начала">
                                                Дата начала*
                                            </label>
                                        </FloatLabel>
                                    </div>
                                    <div className="calendar--block__flex--inp">
                                        <FloatLabel>
                                            <Calendar
                                                disabled={endDateEmpty}
                                                dateFormat="dd.mm.yy"
                                                onChange={(event) =>
                                                    setEndDate(
                                                        (event as any).target.value
                                                    )
                                                }
                                                value={endDate ? new Date(endDate) : undefined}
                                            />
                                            <label htmlFor="Дата начала">
                                                Дата окончания
                                            </label>
                                        </FloatLabel>
                                    </div>
                                </div>

                                <p className="error_message" style={{ marginTop: "5px" }}>{datesError ?? ''}</p>

                                <div className="calendar--checkbox">
                                    <Checkbox id={1} checked={endDateEmpty} handleSelection={() => {
                                        setEndDateEmpty(!endDateEmpty);
                                        setEndDate(null);
                                    }} />
                                    <label>Без даты окончания</label>
                                </div>
                            </div>
                    }
                </div>

                <div className="modal-window-btns">
                    <button
                        className="confirm_btn p_blue p_14 button_reset_style"
                        onClick={() => setOpen(false)}
                    >
                        Отмена
                    </button>
                    <button
                        className="modal-window-margin-btn p_white p_14 button_reset_style save_btn"
                        onClick={EDIT_MODE ? onEdit : onCreate}
                    >
                        Сохранить
                    </button>
                </div>
            </div>
        </div>
    )
        ;
};

export default AddEditProject;
