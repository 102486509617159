import React, { FC, useContext, useEffect, useState} from 'react';
import "./TaskChecklist.css";
import {IHasPermission} from "../../../models/IChekRole";
import {ITaskChecklistGroup} from "../../../models/ITaskChecklistGroup";
import {ITaskChecklist} from "../../../models/ITaskChecklist";
import {Context} from "../../../index";
import {useParams} from "react-router-dom";
import TaskChecklistGroup from "../TaskChecklistGroup/TaskChecklistGroup";
import {ITaskGeneralInfo} from "../../../models/ITask";
import {IMoveColumn} from "../../../models/IMoveColumn";
import {ITaskChecklistGroupEdit} from "../../../models/ITaskChecklistGroupEdit";


interface userAccess {
    userAccess: IHasPermission[]
}

interface handleEditChecklistGroup {
    checklistGroupId: number,
    newName?: string,
    newPosition?: number
}

const TaskChecklist: FC<userAccess> = ({userAccess}) => {
    const {store} = useContext(Context);
    const {id} = useParams();
    const [checklistGroups, setCheklistGroups] = useState<ITaskChecklistGroup[]>([]);
    const [showAddGroupInput, setShowAddGroupInput] = useState<boolean>(false);
    const [nameGroup, setNameGroup] = useState<string>();


    // const {checklistGroup, setCheklistGroup}=useState<ITaskChecklistGroup[]>()
    //drag const
    const [dragEventTarget, setDragEventTarget] = useState<any>();
    const [previewChecklistGroupIndex, setPreviewChecklistGroupIndex] = useState<
        number | undefined
    >();

    const removePreview = () => {
        const elements = document.querySelectorAll(".column-preview");
        elements.forEach((element) => element.remove());
        setPreviewChecklistGroupIndex(undefined);
    };

    const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setDragEventTarget(e.target);
    };

    const createPreviewElement = (content: string) => {
        let newElement = document.createElement("div");
        newElement.className = "column-preview";
        newElement.innerHTML = content;
        newElement.style.backgroundColor = "#149cce6b";
        newElement.style.padding = "25px";
        newElement.style.borderRadius = "6px";
        newElement.style.pointerEvents = "none";
        return newElement;
    };

    // const handleMoveColumn = async (columnId: number, position: number) => {
    //     const moveColumnData: IMoveColumn = {
    //         boardId: Number(boardId),
    //         columnId: columnId,
    //         position: position,
    //     };
    //     await store.moveColumn(moveColumnData);
    //     await store.getAllColumnsBoard(Number(boardId));
    //     setColumns(store.columns);
    // };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();

        const droppedPosition = e.clientY;

        let insertIndex = checklistGroups.length;

        for (let checklistGroup of checklistGroups) {
            const checklistGroupElement = document.getElementById(
                `column-${checklistGroup.id}`
            );
            if (checklistGroupElement) {
                const rect = checklistGroupElement.getBoundingClientRect();
                const midY = rect.top + rect.height / 2;
                if (droppedPosition < midY) {
                    insertIndex = checklistGroups.indexOf(checklistGroup);
                    break;
                }
            }
        }

        if (previewChecklistGroupIndex === insertIndex) return;

        removePreview();

        if (insertIndex < checklistGroups.length) {
            const columnElement = document.getElementById(
                `column-${checklistGroups[insertIndex].id}`
            );
            if (columnElement) {
                let newElement = createPreviewElement(
                    "<div><div>"
                );
                columnElement.insertAdjacentElement("beforebegin", newElement);
                setPreviewChecklistGroupIndex(insertIndex);
            }
        } else {
            const columnElement = document.getElementById(
                `column-${checklistGroups[checklistGroups.length - 1].id}`
            );
            if (columnElement) {
                let newElement = createPreviewElement(
                    "<div><div>"
                );
                columnElement.insertAdjacentElement("afterend", newElement);
                setPreviewChecklistGroupIndex(insertIndex);
            }
        }
    };

    const handleOnDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        try {
            const {checklistGroupId} = JSON.parse(
                e.dataTransfer.getData("application/json")
            );

            const droppedPosition = e.clientY;

            const movedChecklistGroup = checklistGroups.find((c) => c.id === checklistGroupId);
            if (!movedChecklistGroup) return;

            let insertIndex = checklistGroups.length;

            for (let checklistGroup of checklistGroups) {
                const columnElement = document.getElementById(
                    `column-${checklistGroup.id}`
                );
                if (columnElement) {
                    const rect = columnElement.getBoundingClientRect();
                    const midY = rect.top + rect.height / 2;
                    if (droppedPosition < midY) {
                        insertIndex = checklistGroups.indexOf(checklistGroup);
                        break;
                    }
                }
            }

            if (insertIndex !== checklistGroups.indexOf(movedChecklistGroup)) insertIndex--;
            if (insertIndex < checklistGroups.indexOf(movedChecklistGroup)) insertIndex++;

            if (insertIndex === checklistGroups.indexOf(movedChecklistGroup)) {
                return;
            }
            const checklistGroupEdit:handleEditChecklistGroup={
                checklistGroupId: checklistGroupId,
                newPosition:insertIndex

            }


            editGroup (checklistGroupEdit)
            // handleMoveColumn(checklistGroupId, insertIndex);
            removePreview();
        } catch (err) {
            removePreview();
        }
    };

    const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        if (dragEventTarget !== e.target) {
            removePreview();
        }
    };

    const handleDragEnd = () => {
        removePreview();
    };

    // функции связанные с чеклистом
    const loadChecklist = async () => {
        let res = await store.getTaskChecklist(Number(id))
        // setChatMessage(res!);
        console.log('maloy2', res)
        setCheklistGroups(res!)
        console.log('maloy', checklistGroups)
    }

    useEffect(() => {
        (async () => {

            try {
                await loadChecklist()
            } catch (error) {
                console.log(error)
            }
        })();
    }, []);

    useEffect(() => {
        try {
            userAccess.forEach((xx) => {
                if (xx.functionCode == "TaskChatAction") { //Информация по задаче
                    xx.permissions.forEach((yy) => {
                        // if (yy.permissionCode == "view" && yy.isGranted == true) {
                        //     setViewTaskChatMessage(true);
                        // }
                        // if (yy.permissionCode == "create" && yy.isGranted == true) {
                        //     setCreateTaskChatMessage(true);
                        // }
                        // if (yy.permissionCode == "edit" && yy.isGranted == true) {
                        //     setEditTaskChatMessage(true);
                        // }
                        // if (yy.permissionCode == "delete" && yy.isGranted == true) {
                        //     setDeleteTaskChatMessage(true);
                        // }
                    })
                }
                if (store.user.email == "admin@bpmlab.ru") {
                    // setViewTaskChatMessage(true);
                    // setCreateTaskChatMessage(true);
                    // setDeleteTaskChatMessage(true);
                    // setEditTaskChatMessage(true);

                }
            })
        } catch (err) {
            console.log(err)
        }
    }, [userAccess]);

    function test() {
        console.log('maloy', checklistGroups)
    }

    function addGroupInputShow() {
        setShowAddGroupInput(!showAddGroupInput)
    }

    const addNameGroup = (e: any) => {
        if (e.target.value.length > 0) {
            console.log(e.target)
            setNameGroup(e.target.value);
        }
    };

    async function createGroup() {
        if (nameGroup?.length) {
            const group: ITaskChecklistGroup = {
                id: -1,
                name: nameGroup,
                position: checklistGroups.length+1,
                taskId: Number(id!),
            }
            await store.createTaskChecklist(group);
            await loadChecklist();
        }
    }

    const editGroup = (async (handleEditChecklistGroup: handleEditChecklistGroup) => {
        if (checklistGroups?.length) {
            let checklistGroup = checklistGroups.find(xx => xx.id === handleEditChecklistGroup.checklistGroupId);
            if (checklistGroup) {
                const group: ITaskChecklistGroupEdit = {
                    id: handleEditChecklistGroup.checklistGroupId,
                    name: handleEditChecklistGroup.newName,
                    position: handleEditChecklistGroup.newPosition,
                }
                await store.editTaskChecklist(group);
                await loadChecklist();
            }
        }
    })

    return (
        <div className={'checklist--body'}>
            <div>
                <button onClick={test}>Добавить задачу</button>
                <button onClick={addGroupInputShow}>Добавить группу</button>
            </div>
            {showAddGroupInput ?
                <div>
                    <input type={"text"} onInput={addNameGroup}/>
                    <button onClick={createGroup}>Тест</button>
                </div> : null
            }
            <div className="board-menu-columns" onDragEnter={handleDragEnter}
                 onDragOver={(e) => handleDragOver(e)}
                 onDrop={(e) => handleOnDrop(e)}
                 onDragLeave={handleDragLeave}>


                {checklistGroups.map((c, index) => (
                    <TaskChecklistGroup key={c.id}
                                        checklistGroup={c}
                                        onChecklistGroupDragEnd={handleDragEnd}
                                        draggable={index !== 0}
                                        deletable={index !== 0}
                                        userAccess={userAccess}
                                        editGroup={editGroup}
                    />
                ))}
            </div>
        </div>
    );
};

export default TaskChecklist;