import { FC, ReactNode, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IProjectHistoryDisplay } from "../../../models/ProjectModels";
import { HistoryKeyType, ProjectHistoryMap, toStr } from "./ProjectHistoryMap";
import { HistoryEntities } from "../../../models/History/HistoryEntities";
import { HistoryActions } from "../../../models/History/HistoryActions";
import PersonLineDisplay from "../PersonLineDisplay";
import { fullNameFormat } from "../../../helpers/Inicials";
import RightArrow from "../../../assets/rightArrow.svg";
import { deepDiffMapper } from "../../../helpers/deepDiffMapper";

interface IChange {
    oldValue: ReactNode;
    newValue: ReactNode;
    valueName: string | undefined;
}

interface IProjectHistoryDisplayExtended extends IProjectHistoryDisplay {
    changes: IChange[];
}

const toEntityLink = (type?: string, id?: number, name?: string): ReactNode => {
    if (name && id && type && type !== "projectTeam") {
        let url = "";
        if (type == "task") url = `/task/${id}`;
        else if (type == "project") url = `/project/${id}`;
        else if (type == "board") url = `/board/${id}`;
        return <Link to={url}>{name}</Link>;
    } else if (name) {
        return <span>{name}</span>;
    }
    return null;
};

function toDisplayDate(dateStr: any): string {
    try {
        let date = new Date(dateStr);
        const padWithZero = (value: number) =>
            value.toString().padStart(2, "0");
        const day = padWithZero(date.getDate());
        const month = padWithZero(date.getMonth() + 1);
        const year = date.getFullYear();
        const hours = padWithZero(date.getHours());
        const minutes = padWithZero(date.getMinutes());

        //Перестанет работать в 2100 году :)
        return `${day}.${month}.${year - 2000} ${hours}:${minutes}`;
    } catch (err: any) {
        return "-";
    }
}

interface HistoryTableProps {
    openOnFullWindow?: boolean;
    records: IProjectHistoryDisplay[];
    onScrolledToBottom: (e: any) => void;
}

const HistoryTable: FC<HistoryTableProps> = ({
    openOnFullWindow,
    records,
    onScrolledToBottom,
}) => {
    const [recordsForView, setRecordsForView] = useState<
        IProjectHistoryDisplayExtended[]
    >([]);

    useEffect(() => {
        setRecordsForView(parseRecords(records));
    }, [records, openOnFullWindow]);

    const onScroll = (e: any) => {
        const { scrollTop, offsetHeight, scrollHeight } = e.target;

        if (1 + scrollTop + offsetHeight >= scrollHeight) {
            onScrolledToBottom(e);
        }
    };

    const parseRecords = (
        records: IProjectHistoryDisplay[]
    ): IProjectHistoryDisplayExtended[] => {
        if (!records || !records.length) return [];

        // Если приходит несколько изменений в одной записи, раскидываем их по одтельным записям
        records = flatter(records);
        return records.map((r) => {
            try {
                // Если keys == undefined, это ситуация когда в на сервере историю что-то добавили,
                // но на фронте еще нет обработки (a.k.a. записи в projectHistoryMap)
                const keys = ProjectHistoryMap.get(
                    r.entityType,
                    r.typeOfChange
                );

                const diff = deepDiffMapper.map(
                    r.changeData.oldData,
                    r.changeData.newData
                );

                let changes: IChange[] = [];

                if (r.typeOfChange === "create")
                    changes = parseCreatedRecord(r, keys, diff);
                else if (r.typeOfChange === "edit")
                    changes = parseChangedRecord(r, keys, diff);
                else changes = parseDeletedRecord(r, keys, diff);

                return {
                    createdAt: r.createdAt,
                    createdBy: r.createdBy,
                    entityId: r.entityId,
                    entityType: r.entityType,
                    typeOfChange: r.typeOfChange,
                    changeData: r.changeData,
                    changes: changes,
                    hyperlinkEntityType: r.hyperlinkEntityType,
                    hyperlinkId: r.hyperlinkId,
                    hyperlinkName: r.hyperlinkName,
                };
            } catch (err: any) {
                console.error(err);
                return {
                    createdAt: r.createdAt,
                    createdBy: r.createdBy,
                    entityId: r.entityId,
                    entityType: r.entityType,
                    typeOfChange: r.typeOfChange,
                    changeData: r.changeData,
                    changes: [],
                    hyperlinkEntityType: r.hyperlinkEntityType,
                    hyperlinkId: r.hyperlinkId,
                    hyperlinkName: r.hyperlinkName,
                };
            }
        });
    };

    //IMPORTANT У Всех записей в oldData и newData должен быть идентификатор в поле с ключом id
    //TODO: возможно переработать на более отказоустойчивый вариант
    const flatter = (
        records: IProjectHistoryDisplay[]
    ): IProjectHistoryDisplay[] => {
        return records.flatMap((r) => {
            if (
                r.typeOfChange === "create" &&
                Array.isArray(r.changeData.newData)
            ) {
                return r.changeData.newData.map((elem) => {
                    const rCopy = structuredClone(r);
                    rCopy.entityId = rCopy.changeData.newData.id;
                    rCopy.changeData.newData = elem;
                    return rCopy;
                });
            } else if (
                r.typeOfChange === "delete" &&
                Array.isArray(r.changeData.oldData)
            ) {
                return r.changeData.oldData.map((elem) => {
                    const rCopy = structuredClone(r);
                    rCopy.entityId = rCopy.changeData.oldData.id;
                    rCopy.changeData.oldData = elem;
                    return rCopy;
                });
                // newData и oldData должны быть массивы с попарно одинаковыми объектами
            } else if (
                r.typeOfChange === "edit" &&
                Array.isArray(r.changeData.oldData) &&
                Array.isArray(r.changeData.newData)
            ) {
                // Получаем объекты с одинаковыми id из обоих массивов
                const pairs = r.changeData.oldData
                    .filter((oldItem) =>
                        r.changeData.newData.some(
                            (newItem: any) => newItem.id === oldItem.id
                        )
                    )
                    .map((oldItem) => ({
                        first: oldItem,
                        second: r.changeData.newData.find(
                            (newItem: any) => newItem.id === oldItem.id
                        ),
                    }));

                return pairs.map((pair) => {
                    const rCopy = structuredClone(r);
                    rCopy.entityId = pair.first.id;
                    rCopy.changeData.oldData = pair.first;
                    rCopy.changeData.newData = pair.second;
                    return rCopy;
                });
            }
            return r;
        });
    };

    const parseCreatedRecord = (
        record: IProjectHistoryDisplay,
        keys: HistoryKeyType | undefined,
        diff: any
    ): IChange[] => {
        let changes: IChange[] = [];

        if (!keys) {
            Object.keys(diff.newValue).forEach((key) => {
                changes.push({
                    oldValue: undefined,
                    newValue: toStr(diff.newValue[key]),
                    valueName: key,
                });
            });

            return changes;
        }

        if (diff && diff.type === "created") {
            const values = keys.map((key) => {
                return {
                    oldValue: undefined,
                    newValue: key[2](
                        record.changeData.newData[key[0]],
                        openOnFullWindow ?? false
                    ),
                    valueName: key[1],
                };
            });
            changes.push(...values);
        }

        return changes;
    };

    const parseChangedRecord = (
        record: IProjectHistoryDisplay,
        keys: HistoryKeyType | undefined,
        diff: any
    ): IChange[] => {
        let changes: IChange[] = [];

        if (!keys) {
            Object.keys(diff).forEach((key) => {
                if (diff[key].changed)
                    changes.push({
                        oldValue: toStr(diff[key].oldValue),
                        newValue: toStr(diff[key].newValue),
                        valueName: key,
                    });
            });

            return changes;
        }

        const values = keys
            .map((key) => {
                // схема с diff и projectHistoryMap работает отлично со всеми сущностями кроме доступов из ролевой модели
                // для них сделано это отдельное условие
                if (key[0] === "excludePermisson" && diff[key[0]]?.changed) {
                    return {
                        oldValue: key[2](
                            record.changeData.oldData[key[0]],
                            openOnFullWindow ?? false,
                            record.changeData.oldData["role"]
                        ),
                        newValue: key[2](
                            record.changeData.newData[key[0]],
                            openOnFullWindow ?? false,
                            record.changeData.oldData["role"]
                        ),
                        valueName: key[1],
                    };
                }
                if (diff[key[0]]?.changed)
                    return {
                        oldValue: key[2](
                            record.changeData.oldData[key[0]],
                            openOnFullWindow ?? false
                        ),
                        newValue: key[2](
                            record.changeData.newData[key[0]],
                            openOnFullWindow ?? false
                        ),
                        valueName: key[1],
                    };
                return undefined;
            })
            .filter((v) => v !== undefined) as IChange[];
        changes.push(...values);

        return changes;
    };

    const parseDeletedRecord = (
        record: IProjectHistoryDisplay,
        keys: HistoryKeyType | undefined,
        diff: any
    ): IChange[] => {
        let changes: IChange[] = [];

        if (!keys) {
            // Object.keys(diff.oldValue).forEach((key) => {
            //     changes.push({
            //         oldValue: toStr(diff.oldValue[key]),
            //         newValue: undefined,
            //         valueName: key,
            //     });
            // });

            // return changes;

            //Если нет ключей, не отображать ничего
            return [];
        }

        if (diff && diff.type === "deleted") {
            const values = keys.map((key) => {
                return {
                    oldValue: key[2](
                        record.changeData.oldData[key[0]],
                        openOnFullWindow ?? false
                    ),
                    newValue: undefined,
                    valueName: key[1],
                };
            });
            changes.push(...values);
        }

        return changes;
    };

    return (
        <div onScroll={onScroll} className="custom_table history_project_table">
            <table>
                <thead>
                    <tr>
                        <th>ДАТА</th>
                        <th>ОБЪЕКТ</th>
                        <th>ТИП ОБЪЕКТА</th>
                        <th>ТИП ИЗМЕНЕНИЯ</th>
                        <th>ПОЛЬЗОВАТЕЛЬ</th>
                        <th>ИЗМЕНЕНИЕ</th>
                    </tr>
                </thead>
                <tbody>
                    {recordsForView.map((record, index) => (
                        <tr key={index}>
                            <td style={{ width: "fit-content" }}>
                                {toDisplayDate(record.createdAt)}
                            </td>
                            <td
                                style={{
                                    width: "fit-content",
                                    maxWidth: "15%",
                                }}
                            >
                                {toEntityLink(
                                    record.hyperlinkEntityType,
                                    record.hyperlinkId,
                                    record.hyperlinkName
                                )}
                            </td>
                            <td style={{ width: "fit-content" }}>
                                {HistoryEntities.get(record.entityType)}
                            </td>
                            <td style={{ width: "fit-content" }}>
                                {HistoryActions.get(record.typeOfChange)}
                            </td>
                            <td style={{ width: "fit-content" }}>
                                <PersonLineDisplay
                                    name={fullNameFormat(
                                        {
                                            surname: record.createdBy.surname,
                                            name: record.createdBy.name,
                                            middlename:
                                                record.createdBy.middlename,
                                        },
                                        "s N M"
                                    )}
                                    photoId={record.createdBy.photoId}
                                />
                            </td>
                            <td style={{ width: "auto" }}>
                                <div>
                                    {record.changes.map((change) => (
                                        <div
                                            style={{
                                                display: "flex",
                                                flexWrap: "wrap",
                                                wordBreak: "break-all",
                                            }}
                                        >
                                            {change.valueName ? (
                                                <span
                                                    style={{
                                                        fontWeight: "bold",
                                                        marginRight: "5px",
                                                    }}
                                                >
                                                    {change.valueName + ":"}
                                                </span>
                                            ) : null}
                                            {change.oldValue}
                                            {change.oldValue &&
                                            change.newValue ? (
                                                <img
                                                    style={{
                                                        alignSelf: "center",
                                                    }}
                                                    src={RightArrow}
                                                    alt=""
                                                    height={10}
                                                />
                                            ) : null}
                                            {change.newValue}
                                        </div>
                                    ))}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default HistoryTable;
