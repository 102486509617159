import { ReactNode, useState } from "react";
import { DoubleKeyMap } from "../../../helpers/DoubleKeyMap";
import TaskType from "../TaskType";
import TaskPriority from "../TaskPriority";
import TaskStatus from "../TaskStatus";
import { dateToNiceString } from "../../../helpers/dateToNiceString";
import { fullNameFormat } from "../../../helpers/Inicials";
import PersonLineDisplay from "../PersonLineDisplay";
import MarkdownPreview from "../MarkdownPreview/MarkdownPreview";

const funcCodeToStr = new Map<string, string>([
    ["create", "Создание"],
    ["edit", "Редактирование"],
    ["delete", "Удаление"],
    ["view", "Просмотр"],
]);

export const toStr = (obj: any): ReactNode => {
    return JSON.stringify(obj ?? "");
};

const toHoursAndMinutes = (obj: any): ReactNode => {
    const hours = obj / 60;
    const minutes = obj % 60;
    return `${hours} ч. ${minutes} м.`;
};

const toColor = (obj: any): ReactNode => {
    return (
        <div
            className="color-picker-color-variant"
            style={{ backgroundColor: obj, margin: "0px 10px" }}
        ></div>
    );
};

const toType = (obj: any): ReactNode => {
    if (!obj) return <></>;
    const style = obj?.style && JSON.parse(obj?.style);
    return style && obj?.name ? (
        <div style={{ margin: "0px 10px" }}>
            <TaskType name={obj?.name} color={style.color} icon={style.icon} />
        </div>
    ) : (
        <></>
    );
};

const toPriority = (obj: any): ReactNode => {
    if (!obj) return <></>;
    const style = obj?.style && JSON.parse(obj?.style);
    return style && obj?.name ? (
        <div style={{ margin: "0px 10px" }}>
            <TaskPriority
                name={obj?.name}
                color={style.color}
                icon={style.icon}
            />
        </div>
    ) : (
        <></>
    );
};

const toStatus = (obj: any): ReactNode => {
    if (!obj) return <></>;
    const style = obj?.style && JSON.parse(obj?.style);
    return style && obj?.name ? (
        <div style={{ margin: "0px 10px" }}>
            <TaskStatus name={obj?.name} color={style.color} />
        </div>
    ) : (
        <></>
    );
};

const toDate = (obj: any): ReactNode => {
    if (!obj) return <></>;
    return <div>{dateToNiceString(new Date(obj), "dd.MM.yyyy")}</div>;
};

const toObjName = (obj: any): ReactNode => {
    return <div>"{obj?.name ?? ""}"</div>;
};

const toObjRole = (obj: any): ReactNode => {
    return <div>"{obj?.role ?? ""}"</div>;
};

const toAvatar = (obj: any): ReactNode => {
    if (!obj) return <></>;
    return (
        <div>
            <PersonLineDisplay
                photoId={obj.photo}
                name={fullNameFormat(
                    {
                        name: obj.name,
                        middlename: obj.middlename,
                        surname: obj.surname,
                    },
                    "s M N"
                )}
            />
        </div>
    );
};

const toPersonArray = (obj: any): ReactNode => {
    if (!obj) return <></>;
    return (
        <div style={{ display: "flex" }}>
            {obj.map((elem: any) => (
                <PersonLineDisplay
                    photoId={elem.person.photo}
                    name={fullNameFormat(
                        {
                            name: elem.person.name,
                            middlename: elem.person.middlename,
                            surname: elem.person.surname,
                        },
                        "s M N"
                    )}
                />
            ))}
        </div>
    );
};

const toTaskToColumn = (obj: any): ReactNode => {
    if (!obj) return <></>;
    return (
        <div>
            {obj.map((elem: any, index: number) => (
                <span>
                    {elem.column.board.name}.{elem.column.name}
                    {index < obj.length - 1 ? <>, </> : null}
                </span>
            ))}
        </div>
    );
};

const toMarkdown = (obj: any): ReactNode => {
    if (!obj) return <>""</>;
    return (
        <div>
            <MarkdownPreview
                content={`${obj.slice(0, 40)}${obj.length > 40 ? "..." : ""}`}
            ></MarkdownPreview>
        </div>
    );
};

const toTaskTeg = (obj: any): ReactNode => {
    if (!obj) return <></>;
    return (
        <div>
            {obj.map((elem: any) => (
                <div
                    className="tag-display-field tag-display-field__size"
                    style={{
                        backgroundColor: `${elem.teg.color}`,
                        width: "fit-content",
                        lineHeight: "20px",
                    }}
                >
                    <div style={{ color: "rgba(0, 0, 0, 0.7)" }}>
                        {elem.teg.name}
                    </div>
                </div>
            ))}
        </div>
    );
};

const toBoardAccess = (obj: any): ReactNode => {
    if (!obj) return <></>;
    return (
        <div>
            {obj.map(
                (b: any, index: number) =>
                    `${b.board.name}${index < obj.length - 1 ? ", " : ""}`
            )}
        </div>
    );
};

const toExcludePermissions = (
    excludePermissions: any,
    fullScreen: boolean,
    role?: any
): ReactNode => {
    const allFunctions = role.access.map((a: any) => a.systemFunction);
    return fullScreen ? (
        <div>
            {allFunctions.map((f: any) => (
                <div>
                    {f.availablePermissions.map((p: any) => (
                        <div>
                            {excludePermissions.some(
                                (e: any) =>
                                    e.accessPermission.access
                                        .systemFunctionId === f.id &&
                                    e.accessPermission.permissionCode ===
                                        p.availablePermissions.code
                            ) ? (
                                <span
                                    style={{ textDecoration: "line-through" }}
                                >
                                    {f.nameFunction}.
                                    {funcCodeToStr.get(
                                        p.availablePermissions.code
                                    )}
                                </span>
                            ) : (
                                <span>
                                    {f.nameFunction}.
                                    {funcCodeToStr.get(
                                        p.availablePermissions.code
                                    )}
                                </span>
                            )}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    ) : (
        <>...</>
    );
};

export type HistoryKeyType = [
    string,
    string | undefined,
    (obj: any, fullScreen: boolean, obj2?: any) => ReactNode
][];

/**
 * От этой мапы зависит что именно отображаем в таблице
 * сущность + действие = массив ключей в объектах oldData и newData для отображения
 * Массив ключей - это массив троек где:
 * - первый элемент это ключ,
 * - второй это название для отображения,
 * - третий - функция для обработки значения.
 * если в качестве массива ключей задать пустой массив, то столбец "Изменение" будет пустым,
 * если тип дейсвтия = "редактирование", а значение под ключом не изменено, оно не отобразится
 */
export const ProjectHistoryMap: DoubleKeyMap<string, string, HistoryKeyType> =
    new DoubleKeyMap([
        ["project", "create", [["shortDescription", "Название", toStr]]],
        [
            "project",
            "edit",
            [
                ["shortDescription", "Название", toStr],
                ["description", "Описание", toStr],
            ],
        ],
        ["project", "delete", []], // Ну эту запись невозможно увидеть, но пусть будет
        ["subProject", "create", [["shortDescription", "Название", toStr]]],
        [
            "subProject",
            "edit",
            [
                ["shortDescription", "Название", toStr],
                ["description", "Описание", toStr],
            ],
        ],
        ["subProject", "delete", [["shortDescription", "Название", toStr]]],
        ["board", "create", [["name", "Название", toStr]]],
        ["board", "edit", [["name", "Название", toStr]]],
        ["board", "delete", [["name", "Название", toStr]]],
        ["proxyBoard", "create", [["name", "Название", toStr]]],
        ["proxyBoard", "edit", [["name", "Название", toStr]]],
        ["proxyBoard", "delete", [["name", "Название", toStr]]],
        [
            "column",
            "create",
            [
                ["name", "Название", toStr],
                ["columnWeight", "Вес", toStr],
            ],
        ],
        [
            "column",
            "edit",
            [
                ["name", "Название", toStr],
                ["columnWeight", "Вес", toStr],
                ["proxyBoard", "Прокси-доска", toObjName],
                ["color", "Цвет", toColor],
            ],
        ],
        ["column", "delete", [["name", "Название", toStr]]],
        ["projectFile", "create", [["name", "Файл", toStr]]],
        ["projectFile", "delete", [["name", "Файл", toStr]]],
        [
            "task",
            "create",
            [
                ["shortDescription", "Название", toStr],
                ["description", "Описание", toStr],
                ["taskType", "Тип", toType],
                ["priority", "Приоритет", toPriority],
                ["responsible", "Исполнитель", toAvatar],
                ["coResponsibles", "Подписчики", toPersonArray],
                ["status", "Статус", toStatus],
                ["allottedTime", "Срок выполнения", toHoursAndMinutes],
                ["startDate", "Дата начала", toDate],
                ["endDate", "Дата окончания", toDate],
                ["percentageCompletion", "Процент выполнения", toStr],
            ],
        ],
        [
            "task",
            "edit",
            [
                ["shortDescription", "Название", toStr],
                ["description", "Описание", toStr],
                ["taskType", "Тип", toType],
                ["priority", "Приоритет", toPriority],
                ["responsible", "Исполнитель", toAvatar],
                ["coResponsibles", "Подписчики", toPersonArray],
                ["status", "Статус", toStatus],
                ["allottedTime", "Срок выполнения", toHoursAndMinutes],
                ["startDate", "Дата начала", toDate],
                ["endDate", "Дата окончания", toDate],
                ["percentageCompletion", "Процент выполнения", toStr],
                ["taskToColumn", "Положение", toTaskToColumn],
                ["taskToTeg", "Тэги", toTaskTeg],
            ],
        ],
        ["task", "delete", [["shortDescription", "Название", toStr]]],
        [
            "projectTeam",
            "create",
            [
                ["person", "ФИО", toAvatar],
                ["role", "Роль", toObjRole],
            ],
        ],
        [
            "projectTeam",
            "edit",
            [
                ["boardAccesses", "Доступные доски", toBoardAccess],
                ["excludePermisson", "Доступы", toExcludePermissions],
            ],
        ],
        [
            "projectTeam",
            "delete",
            [
                ["person", "Пользователь", toAvatar],
                ["role", "Роль", toObjRole],
            ],
        ],
        ["taskFile", "create", [["name", "Файл", toStr]]],
        ["taskComment", "create", [["text", "Текст", toMarkdown]]],
        ["taskComment", "edit", [["text", "Текст", toMarkdown]]],
        ["taskComment", "delete", [["text", "Текст", toMarkdown]]],
    ]);
