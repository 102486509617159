import {FC, useContext, useEffect, useState} from "react";
import {IColumnDisplay} from "../../../models/IColumnDisplay";
import "./TaskChecklistGroup.css";
import {BoardContext} from "../../../pages/Board/Board";
import DragDropGray from "../../../assets/dragDrop.svg"
import DragDropBlue from "../../../assets/dragDropBlue.svg"
import DeleteColumnBlue from "../../../assets/deleteElementGray.svg"
import DeleteColumnGray from "../../../assets/grayDelete.svg"
import EditColumnBlue from "../../../assets/edit.svg"
import EditColumnGray from "../../../assets/editButtonGray.svg"
import ExitCross from "../../../assets/cancelGreey.svg"
import {AppContext} from "../../../App";
import {Context} from "../../../index";
import {IHasPermission} from "../../../models/IChekRole";
import {ITaskChecklistGroup} from "../../../models/ITaskChecklistGroup";

interface handleEditChecklistGroup {
    checklistGroupId: number,
    newName?: string,
    newPosition?: number
}


interface ITaskChecklistGroupProps {
    checklistGroup: ITaskChecklistGroup;
    onChecklistGroupDragEnd: () => void;
    draggable: boolean;
    deletable: boolean;
    userAccess: IHasPermission[];
    editGroup: (handleEditChecklistGroup: handleEditChecklistGroup) => Promise<void>;
}

const TaskChecklistGroup: FC<ITaskChecklistGroupProps> = ({
                                                           checklistGroup,
                                                           onChecklistGroupDragEnd,
                                                           draggable,
                                                           deletable,
                                                           userAccess,
                                                           editGroup
                                                       }) => {
    const [renameMode, setRenameMode] = useState<boolean>(false);
    // const { handleDeleteColumn, handleEditColumn } = useContext(BoardContext);
    const {showToast} = useContext(AppContext);
    const {store} = useContext(Context);

    //Ограничение прав
    const [editColumn, setEditColumn] = useState(false);
    const [delColumn, setDelColumn] = useState(false);
    const [editColumns, setEditColumns] = useState(false);

    useEffect(() => {
        // userAccess.forEach((xx) => {
        //     if (xx.functionCode == "BoardAction") {
        //         xx.permissions.forEach((yy) => {
        //             if (yy.permissionCode == "edit" && yy.isGranted == true) {
        //                 setEditColumns(true);
        //             }
        //         })
        //     }
        //     if (xx.functionCode == "ColumnAction") {
        //         xx.permissions.forEach((yy) => {
        //             if (yy.permissionCode == "delete" && yy.isGranted == true) {
        //                 setDelColumn(true);
        //             }
        //             if (yy.permissionCode == "edit" && yy.isGranted == true) {
        //                 setEditColumn(true);
        //             }
        //         })
        //     }
        // })

        if (store.user.email == "admin@bpmlab.ru") {
            setEditColumn(true);
            setDelColumn(true);
            setEditColumns(true);
        }
    }, [userAccess]);

    const onDragStart = (e: React.DragEvent<HTMLDivElement>) => {
        setTimeout(() => {
            const columnElement = (e.target as HTMLElement).closest('.board-menu-column');
            if (columnElement) {
                columnElement.classList.add('drag-hidden');
            }
        }, 0);
        e.dataTransfer.setData(
            "application/json",
            JSON.stringify({
                columnId: checklistGroup.id,
            })
        );
    };

    const onDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
        setTimeout(() => {
            const columnElement = (e.target as HTMLElement).closest('.board-menu-column');
            if (columnElement) {
                columnElement.classList.remove('drag-hidden');
            }
        }, 100);
        onChecklistGroupDragEnd();
    };

    const handleNameChange = (e: any) => {
        if (e.key === "Enter") {
            const checklistGroupEdit:handleEditChecklistGroup={
                checklistGroupId: checklistGroup.id,
                newName:e.target.value

            }
            editGroup(checklistGroupEdit);
            setRenameMode(false);
        }
    };

    return (
        <div id={`column-${draggable ? checklistGroup.id : ""}`} className='board-menu-column'>
            <div className="drag-button" draggable={draggable && editColumns} onDragStart={onDragStart}
                 onDragEnd={onDragEnd}>
                {
                    editColumns ?
                        draggable ?
                            <div className="dragDrop__edit">
                                <img src={DragDropBlue}/>
                            </div>
                            :
                            <div className="dragDrop__edit"></div>
                        :
                        draggable ?
                            <div onClick={() => (showToast("У вас нет прав для перемещения колонки!"))}
                                 className="dragDrop__edit">
                                <img src={DragDropGray}/>
                            </div>
                            :
                            <div className="dragDrop__edit"></div>
                }
            </div>
            <div className="board-menu-column__block">
                {renameMode ? (
                    <div className="board-menu-column__block--name">
                        <input
                            type="text"
                            defaultValue={checklistGroup.name ?? ''}
                            onKeyDown={handleNameChange}
                        ></input>
                    </div>
                ) : (
                    <div className="board-menu-column__block--name">
                        {checklistGroup.name}
                    </div>
                )}
                <div className="board-menu-column__block__button">
                    <button
                        onClick={() => (delColumn ? setRenameMode(!renameMode) : showToast("У вас нет прав для редактирования колонки!"))}>
                        {renameMode ?
                            <img src={ExitCross}/>
                            :
                            <img src={editColumn ? EditColumnBlue : EditColumnGray}/>
                        }
                    </button>
                    {/*{deletable ? (*/}
                    {/*    <button*/}
                    {/*        onClick={() => (delColumn ? handleDeleteColumn(checklistGroup.id) : showToast("У вас нет прав для удаления колонки!"))}>*/}
                    {/*        <img src={delColumn ? DeleteColumnBlue : DeleteColumnGray}/>*/}
                    {/*    </button>*/}
                    {/*) : null}*/}
                </div>
            </div>
        </div>
    );
};

export default TaskChecklistGroup;
