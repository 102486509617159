import {FC, useContext, useEffect, useState} from "react";
import {BoardContext} from "../../../pages/Board/Board";
import ColorPicker from "../../Shared/ColorPicker";
import {IColumnDisplay} from "../../../models/IColumnDisplay";
import ColumnWeightInput from "../ColumnWeightInput/ColumnWeightInput";
import EditPen from "../../../assets/edit.svg"
import {IHasPermission} from "../../../models/IChekRole";
import {AppContext} from "../../../App";

interface IBoardColumnOptionsProps {
    column: IColumnDisplay;
    userAccess: IHasPermission[];
}

const BoardColumnOptions: FC<IBoardColumnOptionsProps> = ({column, userAccess}) => {
    const [editToProxy, setEditToProxy] = useState<boolean>(false);
    const [existingBoard, setExistingBoard] = useState<boolean>(false);
    const [proxyBoardName, setProxyBoardName] = useState<string>("");

    const [deleteProxyColumn, setDeleteProxyColumn] = useState(false);
    const [addProxyColumn, setAddProxyColumn] = useState(false);
    const {showToast} = useContext(AppContext);

    const {
        boardsForProxy,
        colorOptions,
        handleMakeColumnCommon,
        handleMakeColumnProxy,
        isProxyBoard,
    } = useContext(BoardContext);


    const [selectedBoardId, setSelectedBoardId] = useState<number | undefined>(
        boardsForProxy[0]?.id
    );

    const {handleEditColumn} = useContext(BoardContext);

    const handleChangeName = (e: any, columnId: number) => {
        if (e.key === "Enter") handleEditColumn(columnId, e.target.value);
    };

    const handleChangeColor = (color: string, columnId: number) => {
        handleEditColumn(columnId, undefined, color);
    };

    const handleChangeProxyBoardName = (e: any) => {
        setProxyBoardName(e.target.value);
    };

    const onMakeProxy = () => {
        if (existingBoard) {
            if (!selectedBoardId) {
                alert("Нет id доски");
                return;
            }
            handleMakeColumnProxy(column.id, undefined, selectedBoardId);
        } else {
            if (!proxyBoardName || proxyBoardName.length <= 0) {
                alert("Нет названия доски");
                return;
            }
            handleMakeColumnProxy(column.id, proxyBoardName, undefined);
        }
        setEditToProxy(false);
        setExistingBoard(false);
    };

    const onMakeCommon = () => {
        handleMakeColumnCommon(column.id);
    };

    const handleSaveWeight = (weight: number) => {
        handleEditColumn(column.id, undefined, undefined, weight);
    };

    useEffect(() => {
        userAccess.forEach((xx) => {
            if (xx.functionCode == "ColumnProxyAction") {
                xx.permissions.forEach((yy) => {
                    if (yy.permissionCode == "create" && yy.isGranted == true) {
                        setAddProxyColumn(true);
                    }
                })
                xx.permissions.forEach((yy) => {
                    if (yy.permissionCode == "delete" && yy.isGranted == true) {
                        setDeleteProxyColumn(true);
                    }
                })
            }
        })
        console.log('isProxyBoard', isProxyBoard)
    }, [userAccess]);

    return (
        <div className="column-settings-popup__position">
            <div className="column-settings-popup">
                <div className="column-settings-popup__name">
                    Имя
                </div>
                <div className="column-settings-popup__input--edit">
                    <input defaultValue={column.name} onKeyDown={(e) => handleChangeName(e, column.id)}
                           type="text" name="column-options-name-field"
                    />
                    <img src={EditPen}/>
                </div>
                <div className="column-settings-popup__block--color">
                    <div className="column-settings-popup__block--color--name">
                        Цвет колонки
                    </div>
                    <div className="column-settings-popup__block--color--block">
                        <ColorPicker
                            onColorSelected={(color) =>
                                handleChangeColor(color, column.id)
                            }
                            options={colorOptions}
                            defaultColor={column.color}
                        />
                    </div>
                </div>
                <div>
                    <ColumnWeightInput
                        column={column}
                        onEditWeight={handleSaveWeight}
                        edit={true}
                    />
                </div>
                {isProxyBoard ? (<div></div>) : (
                    <div className="column__popup--widget__settings--proxy">
                        <div className="column__popup--widget__settings--proxy__add">
                            {editToProxy ? (
                                <div>
                                    {boardsForProxy && boardsForProxy.length > 0 ? (
                                        <div>
                                            {/*<label>Существующая доска</label>*/}
                                            {/*<input*/}
                                            {/*    type="checkbox"*/}
                                            {/*    checked={existingBoard}*/}
                                            {/*    onChange={() =>*/}
                                            {/*        setExistingBoard(!existingBoard)*/}
                                            {/*    }*/}
                                            {/*></input>*/}
                                        </div>
                                    ) : null}
                                    {existingBoard ? (
                                        <div>
                                            <select
                                                onChange={(e) =>
                                                    setSelectedBoardId(
                                                        Number(e.target.value)
                                                    )
                                                }
                                            >
                                                {boardsForProxy.map((b) => (
                                                    <option key={b.id} value={b.id}>
                                                        {b.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    ) : (
                                        <div className="column__popup--widget__settings--proxy__name__board">
                                            <input
                                                value={proxyBoardName}
                                                onChange={handleChangeProxyBoardName}
                                                placeholder="Название доски"
                                            ></input>
                                        </div>
                                    )}
                                    <button onClick={onMakeProxy}>Сохранить</button>
                                </div>
                            ) : null}
                        </div>
                        {column.proxyBoardId ? (
                            <button style={deleteProxyColumn ? {} : {backgroundColor: "rgb(175, 175, 175)"}}
                                    onClick={() => {
                                        deleteProxyColumn ? onMakeCommon() : showToast("У вас нет доступа на удаление прокси столбца!")
                                    }}>
                                Сделать обычным
                            </button>
                        ) : (isProxyBoard ? <div></div> :
                                <button style={addProxyColumn ? {} : {backgroundColor: "rgb(175, 175, 175)"}}
                                        onClick={() => {
                                            addProxyColumn ? setEditToProxy(!editToProxy) : showToast("У вас нет доступа на создание прокси столбца!")
                                        }}>
                                    {editToProxy ? "Закрыть" : "Сделать прокси"}
                                </button>
                        )}
                    </div>)}
            </div>
        </div>
    );
};

export default BoardColumnOptions;
