export const HistoryEntities = new Map<string, string>([
  ["user", "Пользователь"],
  ["project", "Проект"],
  ["task", "Задача"],
  ["projectFile", "Файл проекта"],
  ["board", "Доска"],
  ["proxyBoard", "Прокси-доска"],
  ["subProject", "Подпроект"],
  ["projectTeam", "Команда"],
  ["column", "Колонка"],
  ["role", "Роль"],
  ["securitySettings", "Настройки безопасности"],
  ["systemSettings", "Настройки системы"],
  ["taskFile", "Файл задачи"],
  ["taskComment", "Комментарий"],
]);

