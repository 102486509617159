export default class FunctionSystem {
    constructor() {}

    //Возможно категория неверная
    getSystemFunction: string[] = [
        "SystemProjectAction"
    ]

    getProjectFunction: string[] = [
        "ProjectAction",
        "SubProjectAction",
        "BoardAction",
        "ProjectFileAction",
        "ProjectHistory",
        "ProjectTeamAction",
        "TaskAction"
    ];

    getProjectBoardFunction: string[] = [
        "BoardAction",
        "TaskAction",
        "ColumnAction",
        "TaskAction",
        "ColumnProxyAction"
    ];

    getTaskFunction: string[] = [
        "TaskFileAction",
        "TaskChatAction",
        "TaskAction"
    ];

    getAppFunction: string[] = [
        "UserMonitorAction",
        "SystemAction"

    ];
}