import React, {useContext, useEffect, useState} from "react";
import { observer } from "mobx-react-lite";
import { Context } from "../../../index";
// import "../../styles/form.pc.css";
import "../../../globalstyles/input.pc.css";
// import "../../../globalstyles/background.pc.css";
// import "../../../globalstyles/button.pc.css";
import "../../../globalstyles/authorization.pc.css";
// import "../../../globalstyles/authorization.pc.css";

import Background from "../../../assets/backgroundLogin.jpg";
import CustomLink from "../../Shared/CustomLink/CustomLink";

import Logo from "../../../assets/mainLogo.svg";
import GoogleLogo from "../../../assets/googleLogo.svg";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import PrivacyPolicyLink from "../../Shared/PrivacyPolicyLink";
import Bacground from "../../../assets/backgroundLogin.jpg";

const RegistrationForm = () => {
    const MIN_PASSWORD_LENGTH = 8;

    const [infoMode, setInfoMode] = useState<boolean>(false);

    const [email, setEmail] = useState<string>();
    const [password, setPassword] = useState<string>();
    const [passwordRepeat, setPasswordRepeat] = useState<string>();
    const [firstName, setFirstName] = useState<string>();
    const [surname, setSurname] = useState<string>();
    const [middleName, setMiddleName] = useState<string>();

    const [emailError, setEmailError] = useState<string>();
    const [nameError, setNameError] = useState<string>();
    const [surnameError, setSurnameError] = useState<string>();
    const [passwordError, setPasswordError] = useState<string>();
    const [passwordRepeatError, setPasswordRepeatError] = useState<string>();
    const [nameCompany, setNameCompany] = useState<string | undefined>("");
    const [descriptionCompany, setDescriptionCompany] = useState<string | undefined>("");
    const [hrefLogoCompany, setHrefLogoCompany] = useState<string | undefined>("");
    const [hrefCoverCompany, setHrefCoverCompany] = useState<string | undefined>("");

    const { store } = useContext(Context);

    const handleRegister = async () => {
        const emailErr = validateEmail();
        const nameErr = validateName();
        const surnameErr = validateSurname();
        const passwordErr = validatePassword();
        const passwordRepeatErr = validateRepeatPassword();

        if (emailErr || nameErr || passwordErr || passwordRepeatErr || surnameErr)
            return;

        try {
            const res = await store.registration(
                email ?? "",
                password ?? "",
                firstName?.trim() ?? "",
                surname?.trim() ?? "",
                middleName?.trim() ?? ""
            );
            if (res == 409) {
                setEmailError("Пользователь с таким email уже существует");
                return;
            }
            setInfoMode(true);
        } catch (err) {}
    };

    const validateEmail = (): boolean => {
        let emailErr = undefined;

        if (!email) {
            emailErr = "Введите email";
        } else if (!isValidEmail(email)) {
            emailErr = "Некорректный email";
        }

        setEmailError(emailErr);
        return emailErr !== undefined;
    };

    const loadLogo = async () => {
        const picture = await store.getSystemLogo();
        setHrefLogoCompany(picture?.length > 0 ? picture : Logo)
    };

    const loadCover = async () => {
        const picture = await store.getSystemCover();
        setHrefCoverCompany(picture.length > 0 ? picture : Bacground)
    };

    const validateName = (): boolean => {
        let nameErr = undefined;

        if (!firstName || firstName.trim().length === 0) {
            nameErr = "Заполните имя";
        }

        setNameError(nameErr);
        return nameErr !== undefined;
    };

    const validateSurname = (): boolean => {
        let surnameErr = undefined;

        if (!surname || surname.trim().length === 0) {
            surnameErr = "Заполните фамилию";
        }

        setSurnameError(surnameErr);
        return surnameErr !== undefined;
    };

    const validatePassword = (): boolean => {
        let passwordErr = undefined;

        if (!password) {
            passwordErr = "Введите пароль";
        } else if (password.length < MIN_PASSWORD_LENGTH) {
            passwordErr = `Минимальная длина пароля - ${MIN_PASSWORD_LENGTH} символов`;
        }

        setPasswordError(passwordErr);
        return passwordErr !== undefined;
    };

    const validateRepeatPassword = (): boolean => {
        let passwordRepeatErr = undefined;

        if (!passwordRepeat) {
            passwordRepeatErr = "Введите подтверждение пароля";
        } else if (passwordRepeat !== password) {
            passwordRepeatErr = `Пароли не совпадают`;
        }

        setPasswordRepeatError(passwordRepeatErr);
        return passwordRepeatErr !== undefined;
    };

    const isValidEmail = (email: string): boolean => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    };

    function reloadPage() {
        window.location.reload();
    }

    useEffect(() => {
        (async () => {
            try {
                const data = await store.getPublicSettings();
                loadLogo();
                loadCover();
                if (data?.length) {
                    let description = data.find(xx => xx.paramName == "description");
                    if (description) {
                        setDescriptionCompany(description.value);
                    } else {
                        setDescriptionCompany("Слоган компании пользователя");
                    }

                    let name = data.find(xx => xx.paramName == "name");
                    if (name) {
                        setNameCompany(name.value);
                    } else {
                        setNameCompany("Development CS");
                    }
                } else {
                    setDescriptionCompany("Слоган компании пользователя");
                    setNameCompany("Development CS");
                }

                if (store.linkNewPerson != "" && store.userWithStatus.email != "") {
                    setFirstName(store.userWithStatus.name)
                    setSurname(store.userWithStatus.surname)
                    setMiddleName(store.userWithStatus.middlename)
                    setEmail(store.userWithStatus.email)
                }

            } catch (error) {
                console.log(error)
            }
        })();
    }, []);

    if (infoMode) {
        return (
            <div className="form_main_center">
                <div className="form_container form_container_fullscreen form_container_center">
                    <div className="form_logo">
                        <img src={Logo} alt="Логотип компании" />
                    </div>
                    <div>
                        <h2 className="form_header form_text">
                            {store.linkNewPerson != "" ?
                                "Регистрация пройдена успешно!"
                                :
                                "Письмо отправлено на указанную почту"
                            }
                        </h2>
                    </div>
                    <div className="form_description">
                        <p className="form_text">
                            {store.linkNewPerson != "" ?
                                "Прейдите на стринцу входа"
                            :
                                "Для подтверждения регистрации перейдите по ссылке из\n" +
                                "                            письма"
                            }
                        </p>
                    </div>
                    <div className="form_flex_row">
                        <p className="p_blue p_14 pointer_custom">
                            <div onClick={reloadPage}>Вход</div>
                        </p>
                    </div>
                </div>
                <img
                    src={Background}
                    alt="Фон компании"
                    className="background_image_fullscreen"
                />
            </div>
        );
    }

    return (
        <div
            className="form_main"
            onKeyDown={(e) => {
                if (e.key === "Enter") {
                    handleRegister();
                }
            }}
        >
            <div className="form_container">
                <div className="form_inner">
                    <div className="form_logo">
                        <img src={hrefLogoCompany} alt="Логотип компании" />
                    </div>
                    <div>
                        <h2>{store.linkNewPerson != "" ? "Завершите регистрацию" :"Регистрация"}</h2>
                    </div>
                    <div className="login_in_with_Google pointer_custom">
                        <img src={GoogleLogo} alt="Логотип google" />
                        Зарегистрироваться через Google
                    </div>
                    <div className="login_in_with_Email form_flex_row">
                        <div></div>
                        <a href="">или через Email</a>
                        <div></div>
                    </div>
                    <div
                        className={`input_form_login ${
                            emailError ? "input_with_error" : ""
                        }`}
                    >
                        <label className="input_label" htmlFor="login_email">
                            Email*
                        </label>
                        <InputText
                            id="login_email"
                            value={email}
                            placeholder="email"
                            onBlur={validateEmail}
                            autoComplete="email"
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => setEmail(e.target.value)}
                        />
                        {emailError ? (
                            <p className="error_message">{emailError}</p>
                        ) : null}
                    </div>
                    <form autoComplete="off">
                        <div className="input_form_name">
                            <div
                                className={`input_form_login input_form_surname ${
                                    surnameError ? "input_with_error" : ""
                                }`}
                            >
                                <label
                                    className="input_label"
                                    htmlFor="login_surname"
                                >
                                    Фамилия*
                                </label>
                                <InputText
                                    id="login_surname"
                                    value={surname}
                                    placeholder="Фамилия"
                                    onBlur={validateSurname}
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                    ) => setSurname(e.target.value)}
                                />
                                {surnameError ? (
                                    <p className="error_message">
                                        {surnameError}
                                    </p>
                                ) : null}
                            </div>
                            <div
                                className={`input_form_login input_form_first_name ${
                                    nameError ? "input_with_error" : ""
                                }`}
                            >
                                <label
                                    className="input_label"
                                    htmlFor="login_first_name"
                                >
                                    Имя*
                                </label>
                                <InputText
                                    id="login_first_name"
                                    value={firstName}
                                    placeholder="Имя"
                                    onBlur={validateName}
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                    ) => setFirstName(e.target.value)}
                                />
                                {nameError ? (
                                    <p className="error_message">{nameError}</p>
                                ) : null}
                            </div>
                            <div
                                className={`input_form_login input_form_middle_name`}
                            >
                                <label
                                    className="input_label"
                                    htmlFor="middle_name"
                                >
                                    Отчество
                                </label>
                                <InputText
                                    id="middle_name"
                                    value={middleName}
                                    placeholder="Отчество"
                                    autoComplete="off"
                                    onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                    ) => setMiddleName(e.target.value)}
                                />
                            </div>
                        </div>
                    </form>
                    <div
                        className={`input_form_login ${
                            passwordError ? "input_with_error" : ""
                        }`}
                    >
                        <label className="input_label" htmlFor="login_pass">
                            Пароль*
                        </label>
                        <Password
                            id="login_pass"
                            value={password}
                            onBlur={validatePassword}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => setPassword(e.target.value)}
                            feedback={false}
                            placeholder="********"
                            toggleMask
                        />
                        {passwordError ? (
                            <p className="error_message">{passwordError}</p>
                        ) : null}
                    </div>
                    <div
                        className={`input_form_login ${
                            passwordRepeatError ? "input_with_error" : ""
                        }`}
                    >
                        <label className="input_label" htmlFor="login_pass">
                            Подтверждение пароля*
                        </label>
                        <Password
                            id="login_pass_repeat"
                            value={passwordRepeat}
                            onBlur={validateRepeatPassword}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => setPasswordRepeat(e.target.value)}
                            feedback={false}
                            placeholder="********"
                            toggleMask
                        />
                        {passwordRepeatError ? (
                            <p className="error_message">
                                {passwordRepeatError}
                            </p>
                        ) : null}
                    </div>
                    <div className="login_btn">
                        <Button
                            className="login_btn"
                            label="Зарегистрироваться"
                            onClick={handleRegister}
                        />
                    </div>
                    <div className="form_flex_row form__margin-top">
                        <p className="p_gray p_14_login">Уже есть аккаунт?</p>
                        <p className="p_blue p_14 pointer_custom">
                            <CustomLink to="/login">Войти</CustomLink>
                        </p>
                    </div>

                    <PrivacyPolicyLink />
                </div>
            </div>

            <div className="form_background">
                <div>
                    <h1 className="p_white h1_60 ">{nameCompany}</h1>
                    <h3 className="p_white h3_36">
                        {descriptionCompany}
                    </h3>
                </div>
                <img src={hrefCoverCompany} alt="Фон компании" />
            </div>
        </div>
    );
};

export default observer(RegistrationForm);
