import React, {
    createContext,
    FC,
    useContext,
    useEffect,
    useState,
} from "react";
import TaskInformationForm from "../../component/Task/TaskForm/TaskInformationForm";
import "./Task.css";
import TaskRightInfo from "../../component/Task/TaskRightInfo/TaskRightInfo";
import TaskChat from "../../component/Task/TaskChat/TaskChat";
import { AppContext } from "../../App";
import { Context } from "../..";
import { IBreadCrumb } from "../../models/IBreadCrumb";
import { useParams } from "react-router-dom";
import { ITaskGeneralInfo } from "../../models/ITask";
import { IGetAllTaskPerson } from "../../models/IAllTaskPerson";

import { ICheckRole, IHasPermission } from "../../models/IChekRole";
import FunctionSystem from "../../models/functionCode/functionsSystem";
import { IGetTaskHistoryDisplay } from "../../models/TaskModels";
import { IProjectHistoryDisplay } from "../../models/ProjectModels";
import HistoryTable from "../../component/Shared/HistoryTable/HistoryTable";
import TaskChecklist from "../../component/Task/TaskChecklist/TaskChecklist";

const HISTORY_PAGE_SIZE = 5;

interface TaskContextType {
    taskInformation: ITaskGeneralInfo | undefined;
    loadTaskInfo: () => void;
    getAllTaskPerson: () => void;
    allPerson: IGetAllTaskPerson | undefined;
}

export const TaskContext = createContext<TaskContextType>({
    taskInformation: undefined,
    loadTaskInfo: () => {},
    getAllTaskPerson: () => {},
    allPerson: undefined,
});

const Task: FC = (index) => {
    const { setBreadCrumb, findBoard, sideBarProjects, setNavPanelHighlight } =
        useContext(AppContext);
    const { store } = useContext(Context);
    const [defaultBoardId, setDefaultBoardId] = useState<number>();
    const { boardId, id } = useParams();
    const [allPerson, setAllPerson] = useState<IGetAllTaskPerson | undefined>(
        undefined
    );
    const [taskInformation, setTaskInformation] = useState<ITaskGeneralInfo>();
    const [historyPage, setHistoryPage] = useState<number>(0);
    const [historySkip, setHistorySkip] = useState<number>(0);
    const [haveMoreRecords, setHaveMoreRecords] = useState<boolean>(true);
    const [taskHistory, setTaskHistory] = useState<IProjectHistoryDisplay[]>(
        []
    );

    const tabs: string[] = [
        "Комментарии",
        "Чек-лист",
        "Связанные задачи",
        "История по задаче",
    ];
    const [tab, setTab] = React.useState<number>(0);

    const [userAccess, setUserAccess] = useState<IHasPermission[]>([]);

    // Ограничение прав
    const [viewHistory, setViewHistory] = useState(false);

    useEffect(() => {
        userAccess.forEach((xx) => {
            if (xx.functionCode == "TaskAction") {
                xx.permissions.forEach((yy) => {
                    if (yy.permissionCode == "view" && yy.isGranted == true) {
                        setViewHistory(true);
                    }
                });
            }
        });

        if (store.user.email == "admin@bpmlab.ru") {
            setViewHistory(true);
        }
    }, [userAccess]);

    useEffect(() => {
        (async () => {
            await loadTaskInfo();
            await getAllTaskPerson();
            await loadTaskHistory(0, HISTORY_PAGE_SIZE, false);
            setHistorySkip(HISTORY_PAGE_SIZE);
        })();
    }, []);

    useEffect(() => {
        if (taskInformation && defaultBoardId) {
            let bread: IBreadCrumb[] = [
                {
                    label: "Проекты",
                    url: "/projects",
                },
            ];

            bread = [...bread, ...findBoard(sideBarProjects, defaultBoardId)];
            bread.push({
                label: taskInformation.shortDescription,
                url: "/task/" + taskInformation.id,
            });

            setBreadCrumb(bread);
            setNavPanelHighlight(undefined);
        }
    }, [setBreadCrumb, taskInformation, defaultBoardId, sideBarProjects]);

    //Ограничение доступа
    useEffect(() => {
        (async () => {
            try {
                const functionClass = new FunctionSystem();
                const newCheckRole: ICheckRole = {
                    projectId: Number(id),
                    boardId: undefined,
                    functionCodes: functionClass.getTaskFunction,
                };

                const checkThisRole = await store.hasPermission(newCheckRole);

                setUserAccess(checkThisRole);
            } catch (error) {
                console.log(error);
            }
        })();
    }, []);

    const loadTaskHistory = async (
        skip: number,
        take: number,
        append: boolean
    ): Promise<boolean> => {
        let req: IGetTaskHistoryDisplay = {
            skip: skip,
            take: take,
            filters: {
                taskId: Number(id),
            },
            sort: {
                date: "desc",
            },
        };

        const res = await store.getTaskHistory(req);
        if (!res) return false;
        if (Array.isArray(res)) {
            if (append) setTaskHistory([...taskHistory, ...res]);
            else setTaskHistory(res);
        }
        return res.length > 0;
    };

    const loadTaskInfo = async () => {
        let res = await store.getTask({
            taskId: Number(id),
            boardId: boardId ? Number(boardId) : undefined,
        });
        setTaskInformation(res);
        setDefaultBoardId(res?.board.id);
    };

    const getAllTaskPerson = async () => {
        const res = await store.getAllTaskPerson(Number(id));
        setAllPerson(res);
    };

    function changePage(id: number) {
        setTab(id);
    }

    const onScroll = (e: any) => {
        const { scrollTop, offsetHeight, scrollHeight } = e.target;

        if (1 + scrollTop + offsetHeight >= scrollHeight) {
            handleScrolledToBottom();
        }
    };

    const handleScrolledToBottom = async () => {
        if (tab === 3 && haveMoreRecords) {
            setHistoryPage((prev) => prev + 1);
            setHistorySkip((prev: number) => prev + HISTORY_PAGE_SIZE);

            setHaveMoreRecords(
                await loadTaskHistory(
                    historySkip,
                    (historyPage + 1) * HISTORY_PAGE_SIZE,
                    true
                )
            );
        }
    };

    return (
        <TaskContext.Provider
            value={{
                taskInformation,
                loadTaskInfo,
                getAllTaskPerson,
                allPerson,
            }}
        >
            <div className="widgets_task_container">
                <div className="widgets_task_container_1" onScroll={onScroll}>
                    <div className="widgets_task_container_sub">
                        <TaskInformationForm
                            projectId={taskInformation?.projectId}
                            userAccess={userAccess}
                        />
                    </div>
                    <div className="task_tabs--header">
                        <div className="task__tabs">
                            {tabs.map((tabName, i) => (
                                <button
                                    className={`system__settings--header__button ${
                                        tab === i
                                            ? "system__settings--header__button__selected"
                                            : ""
                                    }`}
                                    onClick={() => changePage(i)}
                                >
                                    {tabName}
                                </button>
                            ))}
                        </div>
                    </div>
                    <div>
                        {tab === 0 ? (
                            <div>
                                <TaskChat userAccess={userAccess} />
                            </div>
                        ) : tab === 1 ? (
                            <div><TaskChecklist userAccess={userAccess}/></div>
                        ) : tab === 2 ? (
                            <div>А это связанные задачи</div>
                        ) : (
                            <div>
                                <div
                                    id="main-history-widget"
                                    className="widget_wrapper"
                                >
                                    <div className="task-history-table-container">
                                        {viewHistory ? (
                                            <HistoryTable
                                                records={taskHistory}
                                                onScrolledToBottom={
                                                    handleScrolledToBottom
                                                }
                                            />
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                </div>
                <div className="widgets_task_container_2">
                    <TaskRightInfo/>
                </div>
            </div>
        </TaskContext.Provider>
    );
};

export default Task;
