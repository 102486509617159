import React, {
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import { observer } from "mobx-react-lite";
import "../../styles/filesComponent.pc.css";
import "./HistoryProject.css";
import "../../../globalstyles/dataTableStyles.css";
import OpenBlock from "../../../assets/openBlock.svg";
import CloseBlockWindow from "../../../assets/arrowCloseWindow.svg";
import { Context } from "../../../index";
import { IHasPermission } from "../../../models/IChekRole";
import { IProjectHistoryDisplay } from "../../../models/ProjectModels";
import { useParams } from "react-router-dom";
import HistoryTable from "../../Shared/HistoryTable/HistoryTable";

const HISTORY_PAGE_SIZE = 5;
const FULL_SCREEN_PAGE_SIZE = 20;

interface IHistoryProjectProps {
    records: IProjectHistoryDisplay[];
    loadPage: (skip: number, take: number, append: boolean) => Promise<boolean>;
    userAccess: IHasPermission[];
}

const History: React.FC<IHistoryProjectProps> = ({
    records,
    loadPage,
    userAccess,
}) => {
    const { id } = useParams();
    const page = useRef<number>(0);
    const [skip, setSkip] = useState<number>(0);
    const [haveMoreRecords, setHaveMoreRecords] = useState<boolean>(true);
    const [openOnFullWindow, setOpenOnFullWindow] = useState(false);
    const { store } = useContext(Context);

    // Ограничение прав
    const [viewHistory, setViewHistory] = useState(false);

    useEffect(() => {
        userAccess.forEach((xx) => {
            if (xx.functionCode == "ProjectHistory") {
                xx.permissions.forEach((yy) => {
                    if (yy.permissionCode == "view" && yy.isGranted == true) {
                        setViewHistory(true);
                    }
                });
            }
        });

        if (store.user.email == "admin@bpmlab.ru") {
            setViewHistory(true);
        }
    }, [userAccess]);

    useEffect(() => {
        (async () => {
            // Если перешли в полноэкранный режим, догружаем задачи до полной страницы
            if (openOnFullWindow && records.length < FULL_SCREEN_PAGE_SIZE) {
                setHaveMoreRecords(
                    await loadPage(0, FULL_SCREEN_PAGE_SIZE, false)
                );
                setSkip(FULL_SCREEN_PAGE_SIZE);
            }
        })();
    }, [openOnFullWindow]);

    useEffect(() => {
        setOpenOnFullWindow(false);
    }, [id]);

    const handleScrolledToBottom = async () => {
        if (haveMoreRecords) {
            page.current++;
            setSkip(
                (prev: number) =>
                    prev +
                    (openOnFullWindow
                        ? FULL_SCREEN_PAGE_SIZE
                        : HISTORY_PAGE_SIZE)
            );

            setHaveMoreRecords(
                await loadPage(
                    skip,
                    page.current *
                        (openOnFullWindow
                            ? FULL_SCREEN_PAGE_SIZE
                            : HISTORY_PAGE_SIZE),
                    true
                )
            );
        }
    };

    return (
        <div
            id="main-history-widget"
            className={
                openOnFullWindow
                    ? "widget_wrapper--full__window"
                    : "widget_wrapper"
            }
        >
            <div
                className={
                    openOnFullWindow ? "widget_wrapper-full__window--block" : ""
                }
            >
                <div className="widget_header">
                    <div className="widjets_header_left">
                        {openOnFullWindow ? (
                            <div
                                onClick={() => setOpenOnFullWindow(false)}
                                className="widgets_header--open__block"
                            >
                                <img src={CloseBlockWindow} />
                            </div>
                        ) : (
                            <div
                                onClick={() => setOpenOnFullWindow(true)}
                                className="widgets_header--open__block"
                            >
                                <img src={OpenBlock} />
                            </div>
                        )}
                        <h2 className="widget-card-header-style">
                            История проекта
                        </h2>
                    </div>
                </div>
                {viewHistory ? (
                    <HistoryTable
                        openOnFullWindow={openOnFullWindow}
                        records={records}
                        onScrolledToBottom={handleScrolledToBottom}
                    />
                ) : null}
            </div>
        </div>
    );
};

export default observer(History);
